import { useEffect, useState } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PianoRoll from './PianoRoll';
import './App.css';

// Piano roll from: https://github.com/g200kg/webaudio-pianoroll

function App() {
  //const URL = " https://b069-14-203-201-162.au.ngrok.io";
  const URL = "http://musictranscriberalb-1829652745.ap-southeast-2.elb.amazonaws.com";
  
  const SEGMENT_LENGTH      = 520;    // milliseconds
  const DESIRED_SAMPLE_RATE = 16000;  // Hz
  // HACK: Assuming 1 webm byte = <COMPRESSION_RATIO> PCM bytes
  const COMPRESSION_RATIO   = 18;
  const DESIRED_SAMPLE_BITS = 16;
  const TEMPO_SCALE         = 5;
  const X_RANGE             = 16;
  const [mediaRecorder, setMediaRecorder] = useState(undefined);
  var recordingBuffer = [];
  // A queue containing the IDs of segments that have been
  // sent to the back end. Used to keep track of the order 
  // that the segments are recorded.
  const segmentIds = [];
  // The time that the recording was last reset or started.
  var startTime = 0;
  // The sum of time intervals between previous start/resume
  // events and the following pause event.
  var totalTime = 0;
  var actualSampleRate = undefined;
  var actualSampleBytes = undefined;
  //Loads a <javascript src=link> element to the page
  const addScript = (link) => {
    const script = document.createElement('script');
    script.src = link;
    script.async = true;
    document.body.appendChild(script);    
  }
  
  useEffect(() => {
    init();
    addScript("https://g200kg.github.io/webaudio-pianoroll/webaudio-pianoroll.js");
  }, [])
  
  async function blobToArray(blob) {
    return Array.from(new Uint8Array(await blob.arrayBuffer()));
  }

  function dataAvailableHandler(recorder) {

    let recordingHeader = undefined;
    const SEGMENT_BYTES = (SEGMENT_LENGTH * actualSampleRate * actualSampleBytes) / 
      (1000 * COMPRESSION_RATIO);
    const MAX_BUFFER_LENGTH = 10 * SEGMENT_BYTES;
    console.log("Sample rate", actualSampleRate);
    console.log("Sample bits", 8*actualSampleBytes);
    console.log("Max buffer length", MAX_BUFFER_LENGTH);
    // Event handler to store each recorded segment into a buffer
    // to be processed asyncronously.

    recorder.ondataavailable = async function(event) {
      // console.log("Data event", event);
      if (!event) {
        console.log("Event undefined");
        return
      }
      const audioBlob = event.data;
      let audioArray = await blobToArray(audioBlob);
      if (audioBlob.size > 0) {
        if (!recordingHeader) {
          recordingHeader = audioArray;
          startTime = event.timeStamp;
        } else {
          // Setting a blob type other than audioBlob.type doesn't convert the
          // data (such as by changing the header) to that type, so if the back end
          // loads the data using the header, this will make no difference.
          // console.log("Audio array", audioBlob, audioArray);
          recordingBuffer = recordingBuffer.concat(audioArray);
          if (recordingBuffer.length > MAX_BUFFER_LENGTH) {
            console.log("Buffer full")
            recordingBuffer.splice(0, recordingBuffer.size - MAX_BUFFER_LENGTH);
          }
          console.log("Buffer", recordingBuffer);
          
          // In the case where the back end is taking more than SEGMENT_LENGTH to
          // process each segment, the back end may respond just after recording
          // a new segment. To avoid the back end being idle, at most one extra
          // segment is sent (i.e. when segmentIds.length == 1). After this,
          // assuming segments have a similar minimum processing time, additional
          // recordings are combined in a circular buffer to send to the back
          // end in larger chunks. This reduces redundant processing and delays,
          // allowing the back end to catch up, especially when using GPUs.
          if (segmentIds.length <= 1 && recordingBuffer.length >= SEGMENT_BYTES) {
            // Calculate the number of bits to give one or more whole
            // segments worth of audio
            // FIXME: Audio is a bit corrupted. Most likely a problem with the header,
            // making harmonics and padding appear in the spectrogram.
            let nSamplesToProcess = recordingBuffer.length;
            // let nSamplesReady = Math.floor(recordingBuffer.length / SEGMENT_BYTES)
            // let nSamplesToProcess = SEGMENT_BYTES * nSamplesReady;
            const blobData = recordingBuffer.splice(0, nSamplesToProcess);

            // Add the file header to the data and send to the back end
            // console.log("Before adding header", recordingHeader, blobData);
            let dataWithHeader = new Uint8Array(recordingHeader.concat(blobData));
            // console.log("After adding header", dataWithHeader);
            const blob = new Blob([dataWithHeader], {type: audioBlob.type});
            // console.log("After converting to blob", blob)
            // BUG: The time offset that each segment is plotted corresponds to
            // the time when it was sent to the back end when it should be 
            // the time that it was recorded.
            let secondsPassed = (event.timeStamp - startTime + totalTime) / 1000;
            callBackend(blob, recorder, secondsPassed);
          } else {
            if (recordingBuffer.length >= MAX_BUFFER_LENGTH) {
              console.log("Waiting for back end. Buffer full")
            } else {
              console.log("Waiting for back end. Samples in buffer: ", recordingBuffer.length)
            }
          }
        }
      } else {
        console.log("Rejected recording of length 0")
      }

    }
  }

  async function init() {
    // var audioCtx = new AudioContext();
    // console.log("Sample rate is ", audioCtx.sampleRate)
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // console.log('getUserMedia supported.');
      // console.log("Available audio constraints", navigator.mediaDevices.getSupportedConstraints())
      await navigator.mediaDevices.getUserMedia ({
        // constraints - only audio needed for this app
        // Constraints are attempted but not guranteed
        // (especially sample rate and sample size)
        audio: {
          channelCount: 1,
          sampleRate: DESIRED_SAMPLE_RATE,
          sampleSize: DESIRED_SAMPLE_BITS
        }
      })
   
      // Success callback
      .then(function(stream) {
        const track = stream.getTracks()[0];
        const audioSettings = track.getSettings();
        actualSampleRate = audioSettings.sampleRate;
        actualSampleBytes = audioSettings.sampleSize / 8;

        // console.log("Track capabilities", track.getCapabilities());
        console.log("Track settings", audioSettings);
        let recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        console.log("Media recorder", mediaRecorder);
        
        // recorder.onstart = function() {
        //   // Get data immediately after starting the recording 
        //   // to generate a header without samples
        //   recorder.requestData();
        // }
        recorder.onpause = function(e) {
          console.log("Recorder paused");
          totalTime += e.timeStamp - startTime;
        }
        recorder.onresume = function(e) {
          console.log("Recorder resumed");
          startTime = e.timeStamp;
        }

        dataAvailableHandler(recorder);
      })
  
      // Error callback
      .catch(function(err) {
        console.log('The following getUserMedia error occurred: ' + err);
      });
    } else {
      console.log('getUserMedia not supported on your browser!');
    }
  }

  function handleRecordButton() {
    /**
     * Makes recordBtn toggle between "Start" and "Pause" states
     * and applies the corresponding action to the mediaRecorder.
     */
    if (!document.getElementById("apiKeyInput").value) {
      alert("Please enter Api Key")
      return
    }
    let button = document.getElementById("recordBtn");
    switch (button.innerText) {
      case "Start":
        recordStart();
        button.innerText = "Pause";
        break;
      case "Pause":
        recordPause();
        button.innerText = "Start";
        break;
      default:
        break;
    }
  }

  function recordStart() {     
    console.log("Starting recording")
    if (mediaRecorder.state === "inactive") {
      // Send data to the back end at regular time intervals
      // The results are not guaranteed to be in the right order.
      mediaRecorder.start(SEGMENT_LENGTH);
      
      // Get data immediately after starting the recording 
      // to generate a header without samples
      // mediaRecorder.requestData();
      console.log(mediaRecorder);
    } else if (mediaRecorder.state === "paused") {
      mediaRecorder.resume()
    }
  }

  function recordPause() {
    console.log("Pausing recording")
    if (mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      // Process the data that is ready at the time of pausing
      mediaRecorder.requestData();
    }
  }

  function recordReset() {
    /**
     * Reset by emptying the buffer, reseting the roll offset
     * and clearing the notes on the roll.
     */
    // Clear the roll
    console.log("Resetting piano roll");
    let piano = document.getElementById("piano");
    if (piano) {
      piano.sequence.length = 0;//TODO: Change to piano.sequence.splice(0, piano.sequence.length);
    }
    // Display piano roll with reset values
    updateSequence([], 0);

    // Clear the state of the front end
    recordingBuffer.splice(0, recordingBuffer.length);
    segmentIds.splice(0, segmentIds.length);

    // HACK: Reset secondsPassed by restarting the recording
    // (would be more efficient to set secondsPassed directly)
    totalTime = 0;
    startTime = 0;
    // dataAvailableHandler(mediaRecorder)

  }

  async function callBackend(body, recorder, secondsPassed) {
    /**
     * Posts and receives data from the back end.
     * @param {Blob} body - The segment of data just recorded.
     * @param {MediaRecorder} mediaRecorder - The object used
     *  to record microphone samples.
     * @param {number} secondsPassed - The offset in seconds of
     * the current segment for positioning in the piano roll.
    */
    // console.log("Calling back end with blob ", body)
    console.log("Calling back end for time offset:", secondsPassed)
    console.log("with blob:", body)
    var myHeaders = new Headers();
    myHeaders.append('Authorization', document.getElementById("apiKeyInput").value);
    try {
      let data = new FormData();
      const uuid = uuidv4()
      data.append(uuid, body);
      data.append("secondsPassed", secondsPassed)
      segmentIds.push(uuid);
      console.log("Segment ids", segmentIds);
      // for (var pair of data.entries()) {
      //   console.log("Posting: " + pair[0] + ", " + pair[1]);
      // }
      
      var result = await fetch(URL + "?form_key=" + uuid, {
        method: "POST", 
        headers: myHeaders,
        body: data,
        redirect: 'follow',
        mode: 'cors'
      })

      result = await result.json()
      if ('error' in result) {
        recorder.stop()
        if (!alert(result.error)) {
          window.location.reload();
        }
      }

      console.log('Received response', result)

      // Remove segment from queue since it has been processed by the back end
      let segmentIndex = segmentIds.indexOf(uuid);
      // Drop result if the segment was deleted from the queue
      // before it could finish processing.
      if (segmentIndex === -1) {
        return
      }
      segmentIds.splice(segmentIndex, 1);

    } catch (e) {
      console.log("error, ", e.text)
      
      if (recorder) {
        recorder.stop()
      }

      if (!alert('Server is down, please try again later')) {
        window.location.reload();
      }
      return
    } 

    updateSequence(result.noteEvents, secondsPassed);
  }
  
  
  function updateSequence(noteEvents, secondsProcessed) {
    /**
     *  Plot the current list of notes on the piano roll.
     *  @param {Object[]} noteEvents - List of objects with 
     *    attributes: 'onset', 'duration' and 'note'.
     *  @param {number} secondsProcessed - Cumulative duration 
     *    of samples processed by the back end. 
     */
    var piano = document.getElementById('piano')
    var sequence = piano.sequence;
    
    // Push all the notes to the piano roll sequence. The times for noteEvents
    // are converted from seconds to ticks.
    for (const event of noteEvents) {
      let scaledOnset = event.onset * TEMPO_SCALE;
      let scaledDuration = event.duration * TEMPO_SCALE;
      sequence.push({t: scaledOnset, n: event.note, g: scaledDuration});
    }
    piano.redraw()
    // Calculate the offset of the piano roll time axis to scroll
    // after a few seconds of audio has been plotted.
    let rollPos = Math.max(0, secondsProcessed * TEMPO_SCALE - X_RANGE);
    piano.xoffset = rollPos;
    console.log("Seconds processed: ", secondsProcessed);
    console.log("Roll pos: ", rollPos);
  }

  return (
    <div className="App">
        {/* TODO: Get tempo from inference result */}
        <PianoRoll xrange={X_RANGE}></PianoRoll>
        <Navbar bg="dark" variant="dark" fixed="bottom">
          <Container>
            <Nav className="me-auto">
              <Button id="recordBtn" onClick={() => handleRecordButton()} variant="dark">
                Start
              </Button>
              <Button onClick={() => recordReset()} variant="dark">Clear</Button>
              <div style={{'color' : 'white', 'margin': 'auto', 'padding' : '5px'}}>Api Key:</div>
              <input id="apiKeyInput" style={{'height' : '20px', 'width' : '320px', 'margin' : 'auto', 'fontSize' : '16px'}}/>
            </Nav>
          </Container>  
        </Navbar>
    </div>
  );
}

export default App;
