/**
 * Wrapper around webaudio-pianoroll to handle updating
 * its parameters in real time.
 */

import { useEffect, useState } from 'react'

const PianoRoll = ({xrange}) => {
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [winHeight, setWinHeight] = useState(window.innerHeight);

  const resizeWindow = () => {
    /** Set window size */
    setWinHeight(window.innerHeight);
    setWinWidth(window.innerWidth);
  };
  
  useEffect(() => {
    /**
     * Update piano roll dimensions on resize
     */
    window.addEventListener("resize", resizeWindow);
    // HACK: Since setting the winHeight state only updates piano.width,
    // rather than re-rendering the whole component including updating the
    // widths of its child DOM elements. Hence, they are manually set
    // here, which has side effects like stretching text incorrect placement
    // of the piano image on the left, until the user refreshes.
    var roll = document.getElementById('wac-body');
    if (roll) {
      document.getElementById('wac-body').style.width = winWidth + "px";
      document.getElementById('wac-pianoroll').style.width = winWidth + "px";
      document.getElementById('wac-markend').style.left = winWidth - 24 + "px";
    }

    return () => window.removeEventListener("resize", resizeWindow);
  })

  return (
    <webaudio-pianoroll id="piano" width={winWidth} height={winHeight - 54}
      xrange={xrange} xscroll="1" markend="64" 
      collt="#FFF" coldk="#EEE" colgrid="#EEE"
      colnote="#00FA9A" colnoteborder="#A6FDDC" 
      editmode="dragpoly" yrange="88" yoffset="21" />
  );
}

export default PianoRoll;